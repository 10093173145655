import React from "react";
import Layout from "../Layout";
import StrapiImage, { STRAPI_IMAGE_SIZES } from "../StrapiImage";
import _ from "lodash";
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import Icofont from "../IcoFont";
import { useTrans } from "../../hooks";

const SelectShareLocation = ({ restaurant, seoData, onSelect }) => {
  const transMsg = useTrans();
  return (
    <>
      <Layout seo={seoData} hideExternalDeliveryModal hasMultipleShareLocations>
        <div
          className="container   text-center  py-5"
          // style={{ maxWidth: 600 }}
        >
          <div className="row pb-2">
            <div className="col-12">
              <StrapiImage
                src={restaurant.logo?.url || ""}
                size={STRAPI_IMAGE_SIZES.THUMBNAIL}
              />
            </div>
          </div>
          <hr />
          <div className="row py-2">
            <div className="col-12 text-center h3">
              {transMsg("selectLocations")}
            </div>
          </div>
          {restaurant.shareLocations?.map((s, i) => {
            return (
              <React.Fragment key={i}>
                <div className="row text-center">
                  <div className="offset-md-3 col-md-6 py-2">
                    <StrapiImage
                      width="100%"
                      src={s.image?.url || ""}
                      size={STRAPI_IMAGE_SIZES.SMALL}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="offset-md-3 col-md-6 text-center py-2">
                    {_.startsWith(s.handle, "http") ? (
                      <>
                        <a
                          className="btn btn-lg btn-outline-primaryOFF    text-uppercase font-weight-bold w-100 round-btn theme-color-back "
                          href={s.handle}
                          target="__blank"
                        >
                          {_.startCase(s.name)}
                          <Icofont
                            className="ml-2"
                            icon="external-link"
                            size={1}
                          />
                        </a>
                      </>
                    ) : (
                      <>
                        <Link
                          to={s.handle || "/"}
                          className="btn btn-lg btn-outline-primaryOFF   text-uppercase font-weight-bold w-100 round-btn theme-color-back "
                          onClick={() => {
                            if (!_.isFunction(onSelect)) return;
                            onSelect();
                          }}
                        >
                          {_.startCase(s.name)}
                        </Link>
                      </>
                    )}
                  </div>
                </div>
                <div className="py-3"></div>
              </React.Fragment>
            );
          })}
        </div>
      </Layout>
    </>
  );
};

export default SelectShareLocation;
